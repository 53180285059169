<!--
 * @Author: lzh
 * @Date: 2022-12-29 17:56:45
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:16:57
 * @Description: file content
-->
<template>
  <div class="edit-QR-code-order-image-text1">
    <div class="cell-b">
      <selfCell title="項-背景色">
        <el-color-picker
          v-model="configs.backgroundColor"
          @change="setValue('backgroundColor')"
          show-alpha
        ></el-color-picker>
      </selfCell>
    </div>
    <div class="cell-b">
      <h3>項-上下间距</h3>
      <el-slider
        v-model="configs.paddingTop"
        show-input
        :max="500"
        @input="setValue('paddingTop')"
      >
      </el-slider>
      <el-slider
        v-model="configs.paddingBottom"
        show-input
        :max="500"
        @input="setValue('paddingBottom')"
      >
      </el-slider>
    </div>
    <div class="cell-b">
      <div class="cell-b">
        <h3>項-標題配置</h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
      <div class="cell-b">
        <h3>項-描述配置</h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
    </div>
    <div v-if="index == 1 || index == 2">
      <div class="cell-b">
        <h3>文本內容-高度</h3>
        <div
          class="item"
          v-for="item,index_ in configs.items[index - 1].xAxisData"
          :key="index_"
        >
          <div class="cell-b">
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}text`]"
              @input="setValue('items')"
              :placeholder="`請輸入-${lItem.name}`"
              type="textarea"
              rows="3"
            />
            <div class="child">
              <el-input-number
                v-model="item.value"
                @change="setValue('items')"
                :min="1"
                :max="100"
                size="mini"
              ></el-input-number>
              <el-button-group class="btn-group">
                <el-button
                  type="primary"
                  icon="el-icon-upload2"
                  :disabled="index_ == 0"
                  size="mini"
                  @click="itemCheck(index_,index_-1)"
                ></el-button>
                <el-button
                  type="success"
                  icon="el-icon-download"
                  :disabled="index_ == configs.items[index - 1].xAxisData.length-1"
                  size="mini"
                  @click="itemCheck(index_,index_+1)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="configs.items[index - 1].xAxisData.length == 1"
                  @click="itemDel(index_,0)"
                ></el-button>
                <el-button
                  type="warning"
                  icon="el-icon-plus"
                  size="mini"
                  @click="itemAdd(index_,0)"
                ></el-button>
              </el-button-group>
            </div>
          </div>
        </div>
      </div>
      <div class="cell-b">
        <div class="d-f-jc-ac">
          <h3>曲線高度</h3>
          <el-input-number
            style="margin-left:20px;"
            v-model="configs.items[index - 1].lineNumber"
            @change="setValue('items')"
            :min="1"
            :max="100"
          ></el-input-number>
        </div>
        <div>
          <h3>旋轉角度</h3>
          <div class="block">
            <el-slider
              v-model="configs.items[index - 1].main2SymbolRotate"
              @input="setValue('items')"
              show-input
              :min="1"
              :max="360"
            >
            </el-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: "editQRCodeORderImageText1",
  mixins: [langEditMixin],
  data () {
    return {
      isEdit: false,
      changNumber: 0
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  props: {
    index: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        };
      },
    },
  },
  methods: {
    // 切換順序
    itemCheck (index, newIndex) {
      let xAxisData = this.configs.items[this.index - 1].xAxisData;
      let _ = xAxisData.splice(index, 1);
      xAxisData.splice(newIndex, 0, _[0]);
      this.$emit('setValue', { key: 'items', value: this.configs.items });
      this.publicChange()
    },
    // 刪除
    itemDel (index, type) {
      if (this.configs.items[type].xAxisData.length <= 1) {
        this.$message({
          message: '至少保留一條數據!',
          type: 'warning'
        });
        return
      }
      let items = this.configs.items[this.index - 1];
      let value1 = items.xAxisData
      value1.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: this.configs.items });
      this.publicChange()
    },
    // 新增
    itemAdd (index, type) {
      let items = this.configs.items[type];
      let value1 = items.xAxisData
      value1.splice(index + 1, 0, this.$util.object.clone(items.xAxisData[0]))
      this.$emit('setValue', { key: 'items', value: this.configs.items });
      this.publicChange()
    },
    publicChange () {
      this.$store.commit('setChangeStatus', new Date() * 1);
    },
    setValue (key) {
      this.publicChange()
      if (key == 'src') key = 'items'
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    removeItem (index) {
      let _ = this.configs.items;
      _.splice(index, 1);
      this.setValue('items');
    },
  }
}
</script>

<style lang="less" scoped>
.edit-QR-code-order-image-text1 {
  .d-f-jc-ac {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .item {
    .child {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      /deep/ .el-input-number {
        width: 110px;
      }
    }
  }
}
</style>